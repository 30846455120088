.product-detail {
    .quantity-input-group {
        width: 250px
    }
    // related product, sub product
    .product-item {
        .card {
            height: 100%;
        }
    }
    .product-description {
        img {
            height: auto;
        }
    }
}

.reviews {
    .review-title  {
        .widget-container {
            vertical-align:baseline !important;
        }
    }
    .review {
        &:first-child  {
            margin: 0;
        }
        .content {
            // max-height: 40px;
            // overflow-y: hidden;
            &.scollable-content {
                max-height: 120px;
                overflow-y: auto;
            }
            p {
                font-size: 0.8rem;
            }
        }
    }
    
    
}