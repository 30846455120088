.rich-text-editor-editor {
   min-height: 275px;
   border: 1px solid rgba(0, 0, 0, 0.125) !important;
   padding-left: 5px !important;
   padding-right: 5px !important;
}

.rich-text-editor-toolbar {
   border: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.rdw-embedded-wrapper .rdw-embedded-modal {
   height: auto;
}

.rdw-link-wrapper .rdw-link-modal {
   height: auto;
}
