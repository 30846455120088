// row-cols-2 row-cols-md-3 row-cols-lg-3 row-cols-xl-3
.product-list-container {
    --adjust: 0px;
    --lite-width: 100vw;
    --max-item-width: calc( 250px - 16px);
    &.lite {
        .product-item {
            --per-row: 4;
            --col-ratio: 1;
            max-width: 500px;
            .main-img {
                max-height: 500px;
            }
        }
        .custom-row-cols {

            @for $i from 1 through 5 {
                &.row-cols-#{$i} {
                    .product-item {
                        justify-content: center;
                        --col-ratio:#{$i};
                    }
                }
            }

           
        }
    }
    .product-item {
            --per-row: 3;
            --col-ratio: 0.75;
            display: flex;
            .card {
                width: 100%;
            }
            .main-img {
                height: 100%;
                --item-wh-ratio: 1;
                --height: ((100vw - 28px - var(--bs-gutter-x)) / 2);
                --height2: ((540px - 28px - var(--bs-gutter-x)) / 2);
                height: calc(min(var(--height), var(--height2)));
                min-height: calc(min(var(--height), var(--height2)));
            }
        }
    }


@include media-breakpoint-up(sm) {

    .product-list-container.lite {
        --lite-width: 100vw;
    }

}
@include media-breakpoint-up(sm) {

    .product-list-container.lite {
        --adjust: 18px;
        --lite-width: 532px;
    }

}
@include media-breakpoint-up(md) {

    .product-list-container.lite {
        --adjust: 18px;
        --lite-width: 712px;
        --max-item-width: calc( 200px - 16px);
    }

    .product-list-container .product-item .main-img {
        --wrapper-padding: calc(2rem);
        --inner-padding: var(--bs-gutter-x) * 0.5 * 4;
        height: calc(  ( 720px + 2px - var(--adjust) - var(--wrapper-padding) - var(--inner-padding)) / var(--per-row) / var(--item-wh-ratio) );
        min-height: calc(  ( 720px + 2px - var(--adjust) - var(--wrapper-padding) - var(--inner-padding)) / var(--per-row) / var(--item-wh-ratio) );
    }
}

@include media-breakpoint-up(lg) {

    .product-list-container.lite {
        --adjust: 18px;
        --lite-width: 952px;
        --max-item-width: calc( 270px - 16px);
    }

    .product-list-container .product-item .main-img {
        --wrapper-padding: calc(2rem);
        --inner-padding: var(--bs-gutter-x) * 0.5 * 4;
        height: calc(  ( var(--col-ratio) * 960px + 2px - var(--adjust) - var(--wrapper-padding) - var(--inner-padding)) / var(--per-row) / var(--item-wh-ratio) );
        min-height: calc(  ( var(--col-ratio) * 960px + 2px - var(--adjust) - var(--wrapper-padding) - var(--inner-padding)) / var(--per-row) / var(--item-wh-ratio) );
    }
}

@include media-breakpoint-up(xl) {

    .product-list-container.lite {
        --adjust: -36px;
        --lite-width: 1132px;
        --max-item-width: calc( 320px - 16px);
    }

    .product-list-container .product-item .main-img {
        --wrapper-padding: calc(6rem);
        --inner-padding: var(--bs-gutter-x) * 0.5 * 4;
        height: calc( ( var(--col-ratio) * 1140px + 12px - var(--adjust) - var(--wrapper-padding) - var(--inner-padding)) / var(--per-row) / var(--item-wh-ratio) );
        min-height: calc( ( var(--col-ratio) * 1140px + 12px - var(--adjust) - var(--wrapper-padding) - var(--inner-padding)) / var(--per-row) / var(--item-wh-ratio) );
    }
}
@include media-breakpoint-up(xxl) {

    .product-list-container.lite {
        --adjust: -36px;
        --lite-width: 1312px;
        --max-item-width: calc( 370px - 16px);
    }

    .product-list-container .product-item .main-img {
        --wrapper-padding: calc(6rem);
        --inner-padding: var(--bs-gutter-x) * 0.5 * 6;
        height: calc( ( var(--col-ratio) * 1320px + 28px - var(--adjust) - var(--wrapper-padding) - var(--inner-padding)) / var(--per-row) / var(--item-wh-ratio) );
        min-height: calc( ( var(--col-ratio) * 1320px + 28px - var(--adjust) - var(--wrapper-padding) - var(--inner-padding)) / var(--per-row) / var(--item-wh-ratio) );
    }
}

// @include media-breakpoint-up(md) {

    .product-list-container.lite {
        --adjust: 18px;
        .custom-row-cols {
            .card {
                border: 0;
                width: calc( min( var(--lite-width) / var(--col-ratio) - var(--bs-gutter-x) , var(--max-item-width) ) );
            }
            .main-img {
                --width-height: calc( min( var(--lite-width) / var(--col-ratio) - var(--bs-gutter-x) , var(--max-item-width) ) );
                // width: calc( var(--width-height) );
                height: calc( var(--width-height) );
                min-height: calc( var(--width-height) );
            }
        }
    }

// }