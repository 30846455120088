// Pagination
.page-item.active .page-link {
    border-bottom: 2px solid;
  }


  @media (max-width: 767px) {

    .ignore-height-xs {
      height: auto !important;
    }
    
}

.page-container {
  margin-top: $spacer * 3;
    padding-top: $spacer * 1.5;
    padding-bottom: $spacer * 1.5;
}