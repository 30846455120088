.ranger-slider-container {
    // height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: $spacer*1.5;
    margin-bottom: $spacer*3;

    .slider {
        position: relative;
        width: 100%;

        .slider__track,
        .slider__range,
        .slider__left-value,
        .slider__right-value {
          position: absolute;
        }

        .slider__track,
        .slider__range {
          border-radius: 3px;
          height: 5px;
        }

        .slider__track {
            background-color: #ced4da;
            width: 100%;
            z-index: 1;
        }
          
        .slider__range {
            background-color: $primary;
            z-index: 2;
        }

        .slider__left-value,
        .slider__right-value {
          color: #333;
          font-size: 12px;
          margin-top: 20px;
        }
        
        .slider__left-value {
          left: 6px;
        }
        
        .slider__right-value {
          right: -4px;
        }

    }

    .thumb {
        &::-webkit-slider-thumb {
            background-color: #f1f5f7;
            border: none;
            border-radius: 50%;
            box-shadow: 0 0 1px 1px #ced4da;
            cursor: pointer;
            height: 18px;
            width: 18px;
            margin-top: 4px;
            pointer-events: all;
            position: relative;
          }
          &::-moz-range-thumb {
            background-color: #f1f5f7;
            border: none;
            border-radius: 50%;
            box-shadow: 0 0 1px 1px #ced4da;
            cursor: pointer;
            height: 18px;
            width: 18px;
            margin-top: 4px;
            pointer-events: all;
            position: relative;
          }
    }

  }
  
  /* Removing the default appearance */
  .thumb,
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }
  
  .thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: calc( 100% - $spacer*2);
    outline: none;
  }
  
  .thumb--zindex-3 {
    z-index: 3;
  }
  
  .thumb--zindex-4 {
    z-index: 4;
  }
  
  .thumb--zindex-5 {
    z-index: 5;
  }
  
