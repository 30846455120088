.my-order {


    .badge:not(.qty-badge) {
        color: rgba(0, 0, 0, 0.87);
        background-color: transparent;
        border: 1px solid #bdbdbd;
        font-weight: 400;
        margin-left: $spacer * 0.5;
    }

    .cart-item {
        border: 0;
        border-bottom: 1px solid #E8E8E8;

        .qty-badge {
            position: absolute;
            right: 0px;
        }
    }

    .extra-item {
        align-items: center;
    }

    .section {
        margin-top: $spacer;
    }

    .sub-title {
        border-bottom: solid 1px rgba(0, 0, 0, 0.125);
        padding-bottom: $spacer;
        margin-bottom: $spacer;
    }

    // sub header 
    h5:not(.sub-title) {
        margin-bottom: $spacer;
    }

    .image-text {
        margin-bottom: 0;
    }
    .details {
        white-space: break-spaces;
    }
    .modal-btn {
        margin: 0;
        padding: 0;
        white-space: pre;
        svg {
            line-height: 1.5;
            vertical-align: sub;
            margin-left: 3px;
        }
    }
}