.image-with-text {
  > .row {
    align-items: center;
  }
  img {
    max-width: 100%;
  }
  .image-with-text-heading {
    height: auto;
    margin-bottom: 10px;
  }

  .carousel-control-prev {
    background-color: black;
    position: absolute;
    top: auto;
    bottom: 0;
    right: 50px;
    left: auto;
    height: 50px;
    width: 50px;
  }
  .carousel-control-next {
    background-color: black;
    position: absolute;
    top: auto;
    bottom: 0;
    height: 50px;
    width: 50px;
  }
}
