.textOverlay {
  position: absolute;
  z-index: 3;
}

.imageView {
  position: absolute;
  z-index: 2;
}

.content {
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin: auto;
}
.textStyle {
  text-align: center;
}