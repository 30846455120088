.blog-detail {
  label {
    min-width: 150px;
  }
  .header {
    height: 270px;

    img {
      object-fit: contain;
    }
  }
}

@media screen and (min-width: 768px) {
  .blog-detail {
    .header {
      height: 480px;
    }
  }
}