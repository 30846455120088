
.categories-container {
    .categories {
        margin-top: $spacer * 3;
        margin-bottom: $spacer * 1.5;
        .category-wrapper {
            display: flex;
            margin-bottom: $spacer*1.5;
        }
        .category {
            width: 100%;
            padding: 0;
            height: 100%;
            --height:((100vw - 40px) / 2 ) - var(--bs-gutter-x);
            --height2:( 540px / 2 )  - var(--bs-gutter-x);
            min-height: calc(  min( var(--height), var(--height2)) );
            a {
                width: 100%;
                height: 100%;
            }
            .card {
                // width: 100%;
                height: 100%;
                color: $white;
                border: 0;
                .card-body {
                    padding: 0;
                }
                .images-in-one {
                    // display: flex;
                    // flex-wrap: wrap;
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-auto-rows: 1fr;
                    justify-items: center;
                    margin: 0;
                    padding: 0;
                    padding: 6px;
                }
            }
         
            .img-fit {
                height: 100%;
                // if cover
                object-fit: cover;
                // if contain
                // object-fit: contain;
                // margin-top: auto;
                // margin-bottom: auto;
            }
            .card-img-overlay {
                display: flex;
                background: rgba(0, 0, 0, 0.325);
                .card-title {
                    margin-top: auto;
                    margin-bottom: auto;
                    width: 100%;
                    text-align: center;
                }
            }
            .sub-image  {
                flex: 50%;
                img {
                    width: 100%;
                    // height: 100%;
                    object-fit: cover;
                    padding:3px
                }
                
            }
            
        }
       
    }
    
}

@include media-breakpoint-up(md) {
    .categories-container .categories .category {
        --category-wrapper-padding: var(--bs-gutter-x) * 0.5 * 2;
        min-height: calc( 720px / 3 - var(--category-wrapper-padding) );
    }
}

@include media-breakpoint-up(lg) {
    .categories-container .categories .category {
        --category-wrapper-padding: var(--bs-gutter-x) * 0.5 * 2;
        min-height: calc( 960px / 4 - var(--category-wrapper-padding) );
    }
}

@include media-breakpoint-up(xl) {
    .categories-container .categories .category {
        min-height: calc( (1140px - 6rem - var(--bs-gutter-x) * 3 ) / 4);
    }
}
@include media-breakpoint-up(xxl) {
    .categories-container .categories .category {
        min-height: calc( (1320px - 6rem - var(--bs-gutter-x) * 3 ) / 4 );
    }
}