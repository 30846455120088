.blog-list.default {
  .item {

    .card-title {
      @extend .text-primary;
    }
    .content {
      max-height: 260px;
      overflow: hidden;
      flex-grow: 1;
      width: 100%;
      .custom-content-box {
        padding-top: $spacer;
      }
      
    }
  }
}