.captcha {
  margin-left: auto;
  margin-right: auto;
  max-width: 350px;
  .captcha-list {
    margin-left:0;
    margin-right:0;
  }
  .captcha-item {
    padding: 0;
    text-align: center;
    margin-bottom: $spacer * 0.5;
    .captcha-image {
      max-width: calc( 100% - 6px);
      cursor: pointer;
      &.selected-captcha {
        outline: 2px solid $active-background;
      }
    }
  }

  .message-box {
    background-color: $active-background;
    color: $white;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 4px;
  }
}
@include media-breakpoint-only(md) { 

  .captcha {
    .captcha-image {
      width: 45px !important;
    }

  }
 }