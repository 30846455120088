.blog-list.extend-cover {
  .carousel {
    .title {
      @extend .text-primary;
    }
    .carousel-indicators [data-bs-target] {
      width: 10px;
      height: 10px;
      border-radius: 100%;
    }

    .carousel-control-prev, .carousel-control-next {
      height: 5rem;
      top: 40vh;

      .carousel-control-prev-icon, .carousel-control-next-icon {
        background-color: #2F3031;
        filter: none;
        color: white;
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
        background-size: 80%;
      }
    }

    .carousel-item .header {
      cursor: pointer;
    }
  }
}