.image-with-text-overlay {
  .image-with-text-overlay-heading {
    height: auto;
    margin-bottom: 10px;
  }

  .content {
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin: auto;
  }

  .imageView {
    position: absolute;
    width: 100%;
    z-index: 2;
  }

  .textOverlay {
    position: absolute;
    z-index: 3;
  }

  .viewAllBtn {
    min-width: 80;
    height: auto;
  }

  .carousel-control-prev {
    background-color: black;
    position: absolute;
    top: auto;
    bottom: 0;
    right: 50px;
    left: auto;
    height: 50px;
    width: 50px;
    z-index: 3;
  }
  .carousel-control-next {
    background-color: black;
    position: absolute;
    top: auto;
    bottom: 0;
    height: 50px;
    width: 50px;
    z-index: 3;
  }
}