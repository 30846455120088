.header {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  // unset all a tag underline style
  .default-navbar {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  a {
    text-decoration: unset;
  }
  .dropdown-menu{
    border-radius: 0;
    .dropdown-item.active, .dropdown-item:active, .dropdown-item:hover, .dropdown-item:focus {
      &:not(.search-item) {
        background-color: unset;
      }
    }
   
    &.more-dropdown-menu {
      > * {
        max-width: 25vw;
      }

      .nav-link {
        @extend .text-truncate;
      }
    }
  }

  .fullwidth-dropdown {
    background: transparent;
    > .dropdown-menu {
      width: 100%;
      margin-top: 0;
      height: min(max(50vh, 400px), 600px);
      background: inherit;
       > .container {
        flex-wrap: wrap;
        > .dropdown-item {
          width: unset;
        }
       }
      .dropdown-menu {
        border: 0;
        min-width: 100%;
        background: inherit;
      }
    }
    
  }
  
  .navbar-toggler {
    border: none;
    &.force-display {
      display: flex;
    }
  }

  .search-icon {
      z-index: 5;
  }
  .search-input {
      transition: all .3s;
      opacity: 0;
      z-index: 4;
      width: 0;
    }
    
  .search-input.show {
      width: 200px;
      transform: translateX(0);
      opacity: 1;
  }

  
  .member {
    align-items: center;
    .member-name {
      @extend .text-truncate;
      max-width: 100px;
    }
    .user-logo {
      max-width: 50px;
      max-height: 34px;
    }
  }


  .nav-link {
    &.member, &.minicart {
      padding-right: $spacer * 0.5;
      padding-left: $spacer * 0.5;
    }

    &.minicart.non-empty {
      margin-right: 15px !important;
    }
  }

  &.custom-layout {
    .member {
      max-width: calc(100% - 50px); // 50px is logout icon
      .member-name {
        max-width: unset;
      }
    }
    .navbar-nav {
      &.flex-column {
        flex-direction: column;
      }
    }
  }

  &.autohide {
 
    .scrolled-navbar {
      background: white;
      display: flex;
      
    }
    .scrolled-down{
      transform:translateY(-100%); transition: all 0.3s ease-in-out;
      .minicart {
        display: none;
      }
    }
    .scrolled-up {
      transform:translateY(0); transition: all 0.3s ease-in-out;
      .minicart {
        display: block;
      }

    }
  }
  .emptycart {
    width: 150px;
  }

}
 

// search item
.search-items {

    .dropdown-item {
        display: flex;
    }
    width: 100%;
    padding-left: 0;

  img {
    float: left;
    margin-right: 12px;
  }
  .item-name-box {
    min-width: 175px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }
  
  .item-name {
    font-size: 16px;
    padding-right:3px;

  }

  .item-code {
    font-size: 14px;
    padding-right:3px;
  }

}

// lang and currency
.lang-dropdown, .currency-dropdown {

  &.inherit {
    .dropdown-toggle {
      color: inherit;
      font-size: inherit;
    }
  }
  
  .dropdown-menu {
      min-width: auto;
      padding: 0.25rem 0;
      border-radius: 0;
  }
  .dropdown-item {
      font-size: 0.75rem;
  }
}

//minicart drawer 
#kopapro-app {
  -webkit-transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

  .minicart-drawer-wrapper {
    > div.MuiDrawer-paper {
      height: auto;
      max-height: calc(100% - 58px);
      top: 58px;
    }
  }
}

.sidebar {

  .inherit  {
    *,.nav-link {
      color: inherit;
      background-color: inherit;
      font-size: inherit;
    }
  }
  .accordion {
    .accordion-item {
      border:0;
    }
    .accordion-button {
      line-height: 34px;
      padding-top: $spacer *0.5;
      padding-bottom: $spacer *0.5;
      padding-left: $spacer ;
      padding-right: $spacer;
      &::after {
        content: none;
      }
    }
    .accordion-body {
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 0;
    }
  }
  .nav-link {
    line-height: 34px;
    color:$list-group-color;
  }
}

header.custom-layout, .sidebar {
  
  // if flex-direction is column, then align is horizontal, justify is vertical
  // if flex-direction is row, then align is vertical, justify is horizontal

  .flex-column.horizontal_alignLeft, .flex-row.vertical_topAlign {
    align-items: flex-start;
  }
  .flex-column.horizontal_center, .flex-row.vertical_middleAlign {
    align-items: center;
  }
  .flex-column.horizontal_alignRight, .flex-row.vertical_bottomAlign {
    align-items: flex-end;
  }
  .flex-column.vertical_topAlign, .flex-row.horizontal_alignLeft {
    justify-content: flex-start;
  }
  .flex-column.vertical_middleAlign, .flex-row.horizontal_center {
    justify-content: center;
  }
  .flex-column.vertical_bottomAlign, .flex-row.horizontal_alignRight {
    justify-content: flex-end;
  }
}

@media (min-width: 576px) {
  .minicart-drawer {
    width: 350px;
  }
  // #kopapro-app.cart-opened {
  //   margin-right: 300px;
  // }
}
@media (max-width: 575.98px) {
  .minicart-drawer {
    width: 300px;
  }
  
}
