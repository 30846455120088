.assistive-touch {
    position: fixed;
    right: 15px;
    top: 100px;
    padding: 5px;
    border: 0;
    z-Index: 4;
}

.preview-configurable-button {
    right: 20px;
    z-index: 10000;
}