.cart {
  .card {
    border: 0;
    .card-body .card-title {
      font-weight: 600;
    }
  }
  
  .cart-item {
    border: 0;
    border-bottom: 1px solid #E8E8E8;
    
    .quantity-input-group {
      width: 130px; 
    }
    
  }
}

.cart-footer {
    display: flex;
    justify-content: space-between;

    .cart-footer-btn {
        width: auto;
        min-width: 100px;
    }
}