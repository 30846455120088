.account-orders {

    .order-item {
        .order-status {
            @extend .badge;
            border-radius: 0;

            &.cancelled {
                @extend .text-muted, .border, .border-2;
            }
            &.completed {
                @extend .text-primary, .border, .border-2, .border-primary;
            }
            &.confirmed {
                @extend .bg-primary;
            }
            &.notConfirmed {
                @extend .bg-warning;
            }
            &.ordered {
                @extend .bg-primary;
            }
        }

        .order-date {
            align-self: end;
        }
    }
}