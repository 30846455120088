.product-tailor {

    .product-tailor-item {
        margin-bottom: $spacer * 0.5;
    }
    .label {
        display: inline-block;
        padding: 0.5rem 0rem 0.5rem;
        font-size: 1.25rem;
    }
}