
.breadcrumb {
    margin-bottom: $spacer * 1.5;
    a {
        text-decoration: none;
        &:hover {
            border-bottom: solid 2px $primary;
        }
    }
}
