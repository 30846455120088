html, body { 
  height: 100%; 
  // background-color: #eee;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.kopapro-app {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}


// .content {
//   flex: 1;
//   padding: 20px;
// }

/* social media icon color */
.icon-twitter {
  color: #1DA1F2 !important;
}
.icon-whatsapp {
  color: #25D366 !important;
}
.icon-facebook {
  color: #4267B2 !important;
}
.icon-line {
  color: #4CC764 !important;
}
.icon-telegram {
  color: #0088cc !important;
}
.icon-instagram  {
  color: #000000 !important;
}
.icon-youtube {
  color: #FF0000 !important;
}
