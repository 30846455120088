.checkout {


  .delivery, .payment, .extra, .summary {
    > .card {
      padding: $spacer;
    }

  }

  .card {
    border: 0;
    .card-body {
      padding: 0;
    }
    
  }
  .form-label[required]:after {
    content: '*';
    color: red;
  }

  .delivery, .payment {
    .methods {
      flex-wrap: wrap;
      display: flex;

      .item {
        align-items: center;
        padding: $spacer*0.5;
        margin-right: $spacer;
        margin-bottom: $spacer *0.5;
        min-width: 150px;
        max-width: 200px;
        display: flex;
        flex-direction: column;
        border: solid 1px #999;
        cursor: pointer;

        img {
          width:100%;
          object-fit: contain;
        }
        &.active {
          border: 0;
          outline: solid 3px $shop-main-color;
        }
        &.disabled {
          cursor: unset;
        }
        
        .item-name {
          width: 100%;
          margin: 0;
          flex:1;
          align-self: center;
          text-align: center;
        }
      }
    }
  }

  .delivery {
    
    .delivery-pickup-address {
      .pickup-address {
        width: fit-content;
      }
    }

    .delivery-address {
      .select-mobile-country {
        min-width: 170px;
      }
    }
  }

  .payment {
    
    .payment-method-coupon {
      flex-wrap: wrap;
      display: flex;

      .payment-method-coupon-item {
        padding: $spacer*0.5;
        margin-right: $spacer;
      }
    }
  }
  
  .extra {
    
      input[type='number']::-webkit-outer-spin-button,
      input[type='number']::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
      }
  }

  .cart-info {
    .item {
      &:not(:first-child) {
        padding-top: 5px;
      }
      &:not(:last-child) {
        @extend .border-bottom;
        padding-bottom: 5px;
      }
      .qty-badge {
        position: absolute;
        right: -10px;
        top :0px;
    }
    }
  }

  .chectout-footer {
    display: flex;
    justify-content: space-between;
    
    .btn {
      width: auto;
      min-width: 100px;
    }
  }

  .discount-code-section {
    p.text-danger, span {
      word-break: break-all;
      overflow-wrap: break-word;
      white-space: pre-wrap;
    }
  }
}

.chectout-coupon-modal {
  .coupon-item {

    cursor: pointer;
    &.active {
      outline: solid 3px $shop-main-color;

      svg {
        position: absolute;
        right: 3px
      }

    }
    &.disabled {
      cursor: unset;
    }

    .coupon-info {
      margin-left: $spacer *0.5;
      margin-right: $spacer *0.5;
      min-width: 0;
     
    }
  }

  .coupon-modal-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.gift-redemption-modal {
  .accordion-body {
   padding:0;
    .quantity-input-group {
      width: 130px;
    }
    .selceted {
      svg.selected-svg {
        position: absolute;
        right: 5px
      }
    }
  }
  
}

.checkout-alert{
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    background-color: rgb(2, 136, 209);
    // display: flex;
    padding: 6px 16px;
    color: rgb(255, 255, 255);
    margin-bottom: 3px;

    &.warning {
      background-color: rgb(237, 108, 2);
    }
    &.success {
      background-color: rgb(46, 125, 50);
    }
    span {
      line-height: 24px;
    }
   
}