.account-coupons-vouchers {
    .card-title {
        display: inline-block;
    }
    .title-logo {
        margin-right: $spacer * 0.5;
    }
    .order-by-ascending-con {
        transform: rotateZ(180deg);
    }
}

.account-coupons-voucher-ledger {
    .card-title {
        display: inline-block;
    }
    .title-logo {
        margin-right: $spacer * 0.5;
    }
}