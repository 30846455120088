.blog-list.date-list {
  .blog-item {
    .title {
      @extend .text-primary;
    }
    &:not(:last-child) {
      @extend .border-bottom;
    }
     .date {
      min-width: 40px;
    }
    .custom-content-box {
      max-height: 60px;
    }
  }
 
}