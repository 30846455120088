 /* for desktop */
 .whatsapp_float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
    &:hover {
        color:#FFF;
    }
    .whatsapp-icon {
        margin-top: 8px;
        font-size: 44px !important;
    }
}


// /* for mobile */
@media screen and (max-width: 767px) {
    
    .whatsapp_float {
        width: 48px;
        height: 48px;
        bottom: 20px;
        right: 20px;
        .whatsapp-icon {
            margin-top: 4px;
            font-size: 40px !important;
        }
    }
}