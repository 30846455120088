$light-text: #ABB0BE;
#popover-cart {
  max-height: 75vh;
  overflow-y: auto;
}
.mini-shopping-cart {
      
      .shopping-cart {
        float: right;
        width: 100%;
        position: relative;
        
        .shopping-cart-footer {
          border-top: 1px solid #E8E8E8;
          border-bottom: 1px solid #E8E8E8;
          padding-top: $spacer * 0.5;
          padding-bottom: $spacer * 0.5;
          .shopping-cart-amount {
            text-align: right;
          }
          .shopping-cart-total {
            font-size: 1.5rem;
          }
         
        }
        
        .shopping-cart-items {
          padding-left: 0;
          li.cart-item {
            display: flex;
            list-style: none;
            margin-bottom: $spacer * 0.5;
            padding-bottom: $spacer * 0.5;
            &:not(:last-child) {
              border-bottom: solid 1px #E8E8E8;
            }
            .information {
              flex: 1;
              min-width: 0;
            }
          }

    
          img, .img-placeholder {
            float: left;
            margin-right: 12px;
          }
          .item-name-box {
            display: flex;
            .item-name {
              font-size: 16px;
              padding-right:3px;
              @extend .text-truncate;
             
            }
          }
          .item-price {
            margin-right: 8px;
          }
          
          .item-quantity {
            color: $light-text;
          }
        }
      }
      
      .clearfix:after {
        content: "";
        display: table;
        clear: both;
      }
}
