.shop-image {
    position: relative;
    text-align: center;
    .caption {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;
    }

    .overlay-background {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: .25;
        z-index: 3;
    }
}

.img-container.zoom {
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    position: relative;
    cursor: zoom-in;

    img.zoomable {
        transition: opacity 0.5s;
        &:hover {
            opacity: 0;
        }
    }
    
}
