.react-select-container {
    .select__control  {
        border-radius: 0;
        box-shadow: none;
        :hover {
            border-color: $shop-main-color;
        }
        &--is-focused, &--menu-is-opened {
            border-color: $shop-main-color !important;
           
        }
       
    }
    .select__option {
        &--is-selected {
            background-color: $primary;
            color: white;
        }
        &--is-focused {
            background-color: $primary-light;
            color: white;
        }
    }
    .select__menu{
        // width: max-content;
        .right {
            margin-left: auto;
            order: 2;
        }
    }
}

@media (max-width: 576px) {
    .react-select-container {
        .select__menu {
            width: calc(100vw - 50px);
        }
    }
    
}