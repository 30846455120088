.banner {
    .info-plate-absolute {
        z-index: 1;
        position: absolute;
        bottom: 10%;
        left: 50%;
        transform: translateX(-50%);
    }
        .info-plate-highlight:hover {
            filter: invert(100%);
        }
    
        .info-plate-underline:hover>h5 {
            text-decoration: underline;
        }
}