

.time-picker-dropdown {
    .dropdown-wrapper{
        opacity: 0;
        height: 0;
        overflow: hidden;
        pointer-events: none;
        position: absolute;
        bottom: -4px;
        transform: translateY(100%);
        right: 0;
        /* width: max-content; */
        z-index: 2;
        align-items: stretch;
        border-radius: 4px;
        transition: 0.3s ease;
    
        &.show{
            height: max-content;
            padding: 12px;
            opacity: 1;
            pointer-events: all;
        }
    }
    .options-container {
        display: flex;
        align-items:flex-start;
        padding: 0 8px;
        padding-bottom: 12px;

        .option-wrapper {
            flex: 1 1 auto;
            height: 224px;
            overflow: auto;
            transition: height 5s;
            &::after {
                height: 96px;
                display: block;
                content: "";
            }
        }

        .time-picker-options {
            width: 100%;
            padding: 0 1px;
            transition: height 5s;

            .time-picker-option {
                padding: 4px 12px;
                transition: height 5s;
                outline: none;
                cursor: pointer;
                &.active {
                    @extend .bg-primary, .text-white;
                }
            }
        }
    }
    .backdrop-overlay {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 1;
    }
}
// .tp_dropdownWrapper.tp_dropdownActive{
//     height: max-content;
//     padding: 12px;
//     opacity: 1;
//     pointer-events: all;
// }




