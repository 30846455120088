.login {

    margin-right: auto;
    margin-left: auto;

    h3.title {
        text-align: center;
        margin-bottom: $spacer * 3;
        padding-top: $spacer *.5;
    }
    .card {
       
        max-width: 450px;
        border: none;
    }
    @media (min-width: 576px) { 
        .card {
            min-width: 380px;
        }
    }
    

    .field {
        margin-bottom: $spacer * .5;
    }

    .condition-separator {
        padding-bottom: $spacer *.5;
        text-align: center;
        display: block;
    }
    .image-container {

        .main-img {
            object-fit: scale-down;
        }

    }
 
    

}