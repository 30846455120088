select.kopapro-select {
    border-left: 0;
    border-right: 0;
    border-top: 0;
}

.btn-link {
    text-decoration: none;

}

.modal-body {
    img { 
        max-width:100%;
        height: auto;
    }
}


.date-picker, .time-picker {
    .form-control[readonly] {
        background-color: unset;
    }
    .MuiInputAdornment-root {
        right: 13px;
        top: -19px;
        position: relative;
    }

}

input {
  &.hide-spin-button::-webkit-outer-spin-button,
  &.hide-spin-button::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
}

.quantity-input-group {
  input {
    text-align: center;
    padding-left: $spacer * 0.25;
    padding-right: $spacer * 0.25;
    border-left: 0;
    border-right: 0;
    &:focus {
      border-color: $input-border-color;
      box-shadow: none;
    }
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  .btn {
    @extend .border;
    border-color: $input-border-color;
    padding-left: $spacer * 0.5;
    padding-right: $spacer * 0.5;
    &.add-btn {
      border-left: 0;
    }
    &.less-btn {
      border-right: 0;
    }
  }
}

// animation
.animation {
    &.wiggle {
        animation: wiggle 2s linear;
      }
      
      @keyframes wiggle {
        0%, 7% {
          transform: rotateZ(0);
        }
        15% {
          transform: rotateZ(-15deg);
        }
        20% {
          transform: rotateZ(10deg);
        }
        25% {
          transform: rotateZ(-10deg);
        }
        30% {
          transform: rotateZ(6deg);
        }
        35% {
          transform: rotateZ(-4deg);
        }
        40%, 100% {
          transform: rotateZ(0);
        }
      }
}

// home page 
.home-component {
  > div {
    overflow: hidden;
  }
}

.kpp-link {
  @extend .btn, .btn-link, .p-0;
  vertical-align: unset;
  line-height: normal;
}