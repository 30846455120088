.newsletter-page {
    .card {
        border: 0;
    }

    .field {
        margin-bottom: $spacer;
    }

    .buttonLabel {
        margin-left: $spacer;
    }
}