
footer.footer  {
    color: $dark;
    margin-top: auto;

    > .container {
        padding-left: $spacer*1.5;
        padding-right: $spacer*1.5;
    }

    a {
        color: $dark;
        text-decoration: none;
    }
    .footer-node {
        margin-top: $spacer;
        margin-bottom: $spacer;
        h6 {
            margin-bottom: $spacer*1.5;
            font-weight: bold;
        }
        ul {
            margin-bottom: 0;
            padding-left: 0;
            list-style: none;
            li {
                margin-bottom: $spacer*0.25;
            }
        }
    }
    .copyright-box {
        background-color: $light;
        color: $text-muted;
        font-size: $small-font-size;
        text-align: center;
        padding: $spacer
    }
   
}