.blog-list.cover-and-content {
  .content {
    height: 100%;
    aspect-ratio: 1;
  }

  .custom-content-box {
    min-height: 50px;
    max-height: 140px;
  }

  .textView {
    height: 100%;
  }
}
