// safety dom
.custom-content-box {
    @extend .text-break;
    * {
      max-width: 100%;
      word-wrap: break-word;
      word-break: break-word;
      
    }
    img {
        height: auto;
    }
  }