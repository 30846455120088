// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
$primary :  #1ea952;

// define custom variables here
$general-radius : 0; // 0.25em

// overrides here
$enable-important-utilities: false;

//  input
$input-border-radius: $general-radius;
$input-btn-focus-width: 0;

// check
$form-check-input-border-radius:  $general-radius;

// button
$btn-border-radius:  $general-radius;

// position
$position-values: (
  0: 0,
  10: 10%,
  25: 25%,
  50: 50%,
  100: 100%
);

// pagination
$pagination-bg:                     transparent;
$pagination-border-width:           0;
$pagination-border-radius:          $general-radius;
$pagination-border-color:           transparent;

$pagination-hover-border-color:     transparent;

$pagination-active-color:          $primary;
$pagination-active-bg:               transparent;
$pagination-active-border-color:    $primary;

// popover
$popover-max-width:                 376px;
$popover-border-radius:             $general-radius;

// accordion
$accordion-border-radius:           $general-radius;
$accordion-button-active-bg:        tint-color(#999, 90%);
$accordion-button-active-color:     shade-color(#333, 10%);
// card
$card-border-radius:  $general-radius;

// list-group
$list-group-border-radius:           $general-radius;
// alert
$alert-border-radius:           $general-radius;

//modal
$modal-content-border-radius:       4px;
$modal-content-border-width:        0;

// 3. Include remainder of required Bootstrap stylesheets
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";



$shop-main-color:$primary;
// additional colors
$custom-colors: (
  "main2": #37BC9B,//$dark
  "kpp-seconary": #37BC9B//$dark
);


// // Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);



// 4. Include any optional Bootstrap components as you like
@import "node_modules/bootstrap/scss/bootstrap";


$hover-background: if($shop-main-color == $color-contrast-light, shade-color($shop-main-color, $btn-hover-bg-shade-amount), tint-color($shop-main-color, $btn-hover-bg-tint-amount));
$hover-border: if($shop-main-color == $color-contrast-light, shade-color($shop-main-color, $btn-hover-border-shade-amount), tint-color($shop-main-color, $btn-hover-border-tint-amount));
$active-background: if($shop-main-color == $color-contrast-light, shade-color($shop-main-color, $btn-active-bg-shade-amount), tint-color($shop-main-color, $btn-active-bg-tint-amount));
$active-border: if($shop-main-color == $color-contrast-light, shade-color($shop-main-color, $btn-active-border-shade-amount), tint-color($shop-main-color, $btn-active-border-tint-amount));

.btn-main, 
.btn-solid-main {
  @include button-variant($shop-main-color, $shop-main-color, #FFF,$hover-background, $hover-border, #FFF, $active-background, $active-border, #FFF, $shop-main-color, $shop-main-color, #FFF);
}

.btn-outline-main {
  @include button-outline-variant($shop-main-color, $white, $shop-main-color, $shop-main-color, $white);
}

$primary-light:  lighten($primary, 10);

