.contact-us {
    h5 {
        padding-top: $spacer *.5;
    }

    .card {
        padding-left: $spacer;
        padding-right: $spacer;
        padding-top: $spacer * 1.5;
        padding-bottom: $spacer * 1.5;
        margin-top: $spacer *.5;
        margin-bottom: $spacer;
        margin-left: auto;
        margin-right: auto;
        align-self: center;
    }

    .field {
        margin-bottom: $spacer;
    }
}
