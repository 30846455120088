// row-cols-2 row-cols-md-3 row-cols-lg-3 row-cols-xl-3
.delivery-method-list-container {

    .delivery-method-item {
        .card {
            height: 100%;
        }

        .main-img {
            object-fit: scale-down;
            --item-wh-ratio: 1;
            --height: ((100vw - 40px) / 2) - 2 * (var(--bs-gutter-x));
            --height2: (540px / 2) - 2 * (var(--bs-gutter-x));
            min-height: calc(min(var(--height), var(--height2)));
        }
    }
}


@include media-breakpoint-up(md) {
    .delivery-method-list-container .delivery-method-item .main-img {
        --wrapper-padding: calc(2rem);
        --inner-padding: var(--bs-gutter-x) * 0.5 * 4;
        min-height: calc(  ( 720px - var(--wrapper-padding) - var(--inner-padding)) / 3 / var(--item-wh-ratio) );
    }
}

@include media-breakpoint-up(lg) {
    .delivery-method-list-container .delivery-method-item .main-img {
        --wrapper-padding: calc(2rem);
        --inner-padding: var(--bs-gutter-x) * 0.5 * 4;
        min-height: calc( ( 0.75 * 960px - var(--wrapper-padding) - var(--inner-padding)) / 3 / var(--item-wh-ratio) );
    }
}

@include media-breakpoint-up(xl) {
    .delivery-method-list-container .delivery-method-list .delivery-method {
        --wrapper-padding: calc(2rem);
        --inner-padding: var(--bs-gutter-x) * 0.5 * 4;
        min-height: calc( ( 0.75 * 1140px - 6rem - var(--wrapper-padding) - var(--inner-padding)) / 3 / var(--item-wh-ratio) );
    }
}
@include media-breakpoint-up(xxl) {
    .delivery-method-list-container .delivery-method-list .delivery-method {
        --wrapper-padding: calc(2rem);
        --inner-padding: var(--bs-gutter-x) * 0.5 * 4;
        min-height: calc( ( 0.75 * 1320px - 6rem - var(--wrapper-padding) - var(--inner-padding)) / 3 / var(--item-wh-ratio) );
    }
}